const list = [
    {
        id: 'home',
        name: 'Inicio'
    },
    {
        id: 'about',
        name: 'Sobre mí'
    },
    {
        id: 'portfolio',
        name: 'Portafolio'
    },
    {
        id: 'experience',
        name: 'Experiencia'
    },
    {
        id: 'contact',
        name: 'Contacto'
    }
];

export default list;